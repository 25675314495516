import {
  enableRecordCommandOperation,
  isAllowedOperation,
  isValidOperation,
  visibleRecordCommandOperation
} from "@/services/record/recordService";
import {
  contextMenuRecordOperation,
  findContextMenuOperation,
  recordOperation,
  recordState,
  recordType
} from "@/model/record/recordModel";
import {
  isAllowedAgendaItemOperation,
  isValidAgendaItemOperation
} from "@/services/agenda/agendaRecordService";
import {
  agendaItemOperation,
  findAgendaItemOperation
} from "@/model/agenda/item/agendaItemModel";
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";

export const batchableRecordsMixin = {
  data() {
    return {
      //batchCommands
      copyCommand: findContextMenuOperation(contextMenuRecordOperation.Copy),
      cutCommand: findContextMenuOperation(contextMenuRecordOperation.Cut),
      visibleBatchDownloadRecordsDialog: false,
      visibleBatchPrintRecordsDialog: false,
      visibleBatchDeleteRecordsDialog: false,
      visibleBatchRestoreRecordsDialog: false,
      visibleBatchLockRecordsDialog: false,
      visibleBatchSetReadOnlyRecordsDialog: false,
      isPurgeOperation: false,
      isUnlockOperation: false,
      isSetNonReadOnlyOperation: false,
      isRecallOperation: false,
      visibleBatchSubmitRecallAgendaItemsDialog: false
    };
  },
  computed: {
    /**
     * Get Batch Command Copy Tooltip
     * @return {string}
     */
    getBatchCommandCopyTooltip() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return `Copy ${this.selectedItems?.length} Records`;
        }
      }
      return `Refer ${this.selectedItems?.length} Records`;
    },

    /**
     * Get Batch Command Cut Tooltip
     * @return {string}
     */
    getBatchCommandCutTooltip() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return `Cut ${this.selectedItems?.length} Records`;
        }
      }
      return `Defer ${this.selectedItems?.length} Records`;
    },

    /**
     * Get Batch Command Restore Tooltip
     * @return {string}
     */
    getBatchCommandRestoreTooltip() {
      return `Restore ${this.selectedItems?.length} Deleted Records`;
    },

    /**
     * Get Batch Command Lock Tooltip
     * @return {string}
     */
    getBatchCommandLockTooltip() {
      return this.isUnlockOperation
        ? `Unlock ${this.selectedItems?.length} Records`
        : `Lock ${this.selectedItems?.length} Records`;
    },

    /**
     * Get Batch Command Set Read Only Tooltip
     * @return {string}
     */
    getBatchCommandReadOnlyTooltip() {
      return this.isSetNonReadOnlyOperation
        ? `Set Not Read only ${this.selectedItems?.length} Records`
        : `Set Read Only ${this.selectedItems?.length} Records`;
    },

    /**
     * Get Batch Command Submit Tooltip
     * @return {string}
     */
    getBatchCommandSubmitTooltip() {
      return `Submit ${this.selectedItems?.length} Items`;
    },

    /**
     * Get Batch Command Recall Tooltip
     * @return {string}
     */
    getBatchCommandRecallTooltip() {
      return `Recall ${this.selectedItems?.length} Items`;
    },

    /**
     *  Get Batch Command Copy Name (Refer in case of Item)
     * @return {string}
     */
    getBatchCommandCopyName() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return "Copy";
        }
      }
      return "Refer";
    },

    /**
     * Get Batch Command Cut Name (Defer in case of Item)
     * @return {string}
     */
    getBatchCommandCutName() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return "Cut";
        }
      }
      return "Defer";
    }
  },
  methods: {
    /**
     * Get Batch Command Delete Tooltip
     * @param {{Boolean}} isPurgeOperation
     * @return {string}
     */
    getBatchCommandDeleteTooltip(isPurgeOperation) {
      return isPurgeOperation
        ? `Permanently Delete ${this.selectedItems?.length} Records`
        : `Delete ${this.selectedItems?.length} Records`;
    },

    /**
     *  Get Command Delete Name based on delete operation
     * @param {{Boolean}} isPurgeOperation
     * @return {string}
     */
    getCommandDeleteName(isPurgeOperation) {
      return isPurgeOperation ? recordOperation.Purge : recordOperation.Delete;
    },

    /**
     * Validate if Delete Operation Allowed
     * @param {{Boolean}} isPurgeOperation
     * @return {boolean}
     */
    isAllowedDeleteOperation(isPurgeOperation) {
      for (const record of this.selectedItems) {
        if (
          isPurgeOperation
            ? !isAllowedOperation(record, recordOperation.Purge)
            : !isAllowedOperation(record, recordOperation.Delete)
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Delete Operation Valid
     * @param {{Boolean}} isPurgeOperation
     * @return {boolean}
     */
    isValidDeleteOperation(isPurgeOperation) {
      for (const record of this.selectedItems) {
        if (
          isPurgeOperation
            ? !isValidOperation(record, recordOperation.Purge)
            : !isValidOperation(record, recordOperation.Delete)
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Restore Operation Allowed
     * @return {boolean}
     */
    isAllowedRestoreOperation() {
      if (!this.isPurgeOperation) return false;
      for (const record of this.selectedItems) {
        if (!isAllowedOperation(record, recordOperation.Restore)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Restore Operation Valid
     * @return {boolean}
     */
    isValidRestoreOperation() {
      for (const record of this.selectedItems) {
        if (!isValidOperation(record, recordOperation.Restore)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Lock Operation Allowed
     * @return {boolean}
     */
    isAllowedLockOperation() {
      for (const record of this.selectedItems) {
        if (
          this.isUnlockOperation
            ? !isAllowedOperation(record, recordOperation.Unlock)
            : !isAllowedOperation(record, recordOperation.Lock)
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Lock Operation Valid
     * @return {boolean}
     */
    isValidLockOperation() {
      for (const record of this.selectedItems) {
        if (
          this.isUnlockOperation
            ? !isValidOperation(record, recordOperation.Unlock)
            : !isValidOperation(record, recordOperation.Lock)
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Read Only Operation Allowed
     * @return {boolean}
     */
    isAllowedReadOnlyOperation() {
      const initialReadOnlyValue = this.selectedItems[0].isReadOnly;

      for (const record of this.selectedItems) {
        if (
          !isAllowedOperation(record, recordOperation.SetReadOnly) ||
          record.isReadOnly !== initialReadOnlyValue
        ) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if ReadOnly Operation Valid
     * @return {boolean}
     */
    isValidReadOnlyOperation() {
      for (const record of this.selectedItems) {
        if (!isValidOperation(record, recordOperation.SetReadOnly)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Submit Operation Allowed
     * @return {boolean}
     */
    isAllowedSubmitOperation() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return false;
        }
        if (!isAllowedAgendaItemOperation(record, agendaItemOperation.submit)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Submit Operation Valid
     * @return {boolean}
     */
    isValidSubmitOperation() {
      for (const record of this.selectedItems) {
        if (!isValidAgendaItemOperation(record, agendaItemOperation.submit)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Recall Operation Allowed
     * @return {boolean}
     */
    isAllowedRecallOperation() {
      for (const record of this.selectedItems) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return false;
        }
        if (!isAllowedAgendaItemOperation(record, agendaItemOperation.recall)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Validate if Recall Operation Valid
     * @return {boolean}
     */
    isValidRecallOperation() {
      for (const record of this.selectedItems) {
        if (!isValidAgendaItemOperation(record, agendaItemOperation.recall)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Get Batch Command Export Tooltip
     * @param action - Export Command Name
     * @return {string}
     */
    getBatchCommandExportTooltip(action) {
      return `${action} ${this.selectedItems?.length} Records`;
    },

    /**
     * Enable Batch Record Export Operation
     * @param operation
     * @return {boolean}
     */
    enableRecordExportOperation(operation) {
      for (const record of this.selectedItems) {
        if (!enableRecordCommandOperation(record, operation)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Visible Batch Record Export Operation
     * @param operation
     * @return {boolean}
     */
    visibleRecordExportOperation(operation) {
      for (const record of this.selectedItems) {
        if (!visibleRecordCommandOperation(record, operation)) {
          return false;
        }
      }
      return true;
    },

    /**
     * on Batch Record Action
     * @param operation
     */
    onBatchRecordAction(operation) {
      try {
        switch (operation) {
          case recordOperation.Download: {
            this.isEmail = false;
            this.visibleBatchDownloadRecordsDialog = true;
            break;
          }
          case recordOperation.Print: {
            this.visibleBatchPrintRecordsDialog = true;
            break;
          }
          case recordOperation.Email: {
            this.isEmail = true;
            this.visibleBatchDownloadRecordsDialog = true;
            break;
          }
          default: {
            this.showSnackbarWarning(`TODO: ${operation}`, true);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Allowed Move Operation - Batch Records
     * @param action - operation (cut/copy)
     * @return {this is *[]|boolean}
     */
    isAllowedMoveOperation(action) {
      try {
        switch (action) {
          case contextMenuRecordOperation.Copy:
            for (const record of this.selectedItems) {
              if (!isAllowedOperation(record, recordOperation.Create)) {
                return false;
              }
            }
            return true;

          case contextMenuRecordOperation.Cut:
            for (const record of this.selectedItems) {
              if (
                !(
                  isAllowedOperation(record, recordOperation.Create) &&
                  isAllowedOperation(record, recordOperation.Delete)
                )
              ) {
                return false;
              }
            }
            return true;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Valid Move Operation - Batch Records
     * @param action - operation (cut/copy)
     * @return {this is *[]|boolean}
     */
    isValidMoveOperation(action) {
      try {
        switch (action) {
          case contextMenuRecordOperation.Copy:
            for (const record of this.selectedItems) {
              if (
                record?.recordTypeId !== recordType.FILE &&
                record?.recordTypeId !== recordType.RECORD &&
                record?.recordTypeId !== recordType.ITEM
              ) {
                return false;
              }
            }
            return this.isEnabledCommandCopy();

          case contextMenuRecordOperation.Cut:
            for (const record of this.selectedItems) {
              if (
                record?.recordTypeId !== recordType.FILE &&
                record?.recordTypeId !== recordType.RECORD &&
                record?.recordTypeId !== recordType.ITEM
              ) {
                return false;
              }
            }
            return this.isEnabledCommandCut();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event on Delete Records (Batch)
     */
    onDeleteRecordsAction() {
      this.visibleBatchDeleteRecordsDialog = true;
    },

    /**
     * Event on Restore Records (Batch)
     */
    onRestoreRecordsAction() {
      this.visibleBatchRestoreRecordsDialog = true;
    },

    /**
     * Event on Lock Records (Batch)
     */
    onLockRecordsAction() {
      this.visibleBatchLockRecordsDialog = true;
    },

    /**
     * Event on Set Read Only Records (Batch)
     */
    onSetReadOnlyRecordsAction() {
      this.visibleBatchSetReadOnlyRecordsDialog = true;
    },

    /**
     * Event on Submit Items (Batch)
     */
    onSubmitItemsAction() {
      this.isRecallOperation = false;
      this.visibleBatchSubmitRecallAgendaItemsDialog = true;
    },

    /**
     * Event on Recall Items (Batch)
     */
    onRecallItemsAction() {
      this.isRecallOperation = true;
      this.visibleBatchSubmitRecallAgendaItemsDialog = true;
    },

    /**
     * Event on Close Submit Recall Agenda Items Dialog
     */
    closeSubmitRecallAgendaItemsDialog() {
      this.visibleBatchSubmitRecallAgendaItemsDialog = false;
      this.isRecallOperation = false;
    },

    /**
     * Enabled Copy Command for Batch Records
     * @return {this is *[]}
     */
    isEnabledCommandCopy() {
      return this.selectedItems.every(item => {
        return (
          item.stateId !== recordState.checkedOut &&
          !item.isLocked &&
          !item.isReadOnly
        );
      });
    },

    /**
     * Enabled Cut Command for Batch Records
     * @return {this is *[]}
     */
    isEnabledCommandCut() {
      return this.selectedItems.every(item => {
        return (
          isValidOperation(item, recordOperation.Delete) &&
          item.stateId !== recordState.checkedOut &&
          !item.isLocked &&
          !item.isReadOnly
        );
      });
    },

    /**
     * Event On Batch Records Move Action
     * @param action
     */
    onMoveRecordsAction(action) {
      try {
        switch (action) {
          case contextMenuRecordOperation.Copy: {
            this.clearSavedRecord();

            const payload = {
              type: contextMenuRecordOperation.Copy,
              records: this.selectedItems
            };
            this.setMovedRecords(payload);

            this.showSnackbarSuccess(
              `Successfully Copied ${this.selectedItems.length} Records`,
              true
            );
            break;
          }

          case contextMenuRecordOperation.Cut: {
            this.clearSavedRecord();

            const payload = {
              type: contextMenuRecordOperation.Cut,
              records: this.selectedItems
            };
            this.setMovedRecords(payload);

            this.showSnackbarSuccess(
              `Successfully Cut ${this.selectedItems.length} Records`,
              true
            );
            break;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * on Event Batch Delete Action
     * @param record
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchDeleteAction(record) {
      this.deleteSelectedRecord = record;
      return await this.recordDelete();
    },

    /**
     * on Event Batch Restore Action
     * @param record
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchRestoreAction(record) {
      this.restoreSelectedRecord = record;
      return await this.recordRestore();
    },

    /**
     * on Event Batch Lock Action
     * @param record
     * @param operation
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchLockAction(record, operation) {
      if (operation?.name === recordOperation.Unlock) {
        this.unlockSelectedRecord = record;
        return await this.recordUnlock();
      }
      this.lockSelectedRecord = record;
      return await this.recordLock();
    },

    /**
     * on Event Batch Set Read Only Action
     * @param record
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchSetReadOnlyAction(record) {
      this.setReadOnlySelectedRecord = record;
      return await this.recordSetReadOnly();
    },

    /**
     * Event on Batch Submit Recall Items Action
     * @param record
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchSubmitRecallItemsAction(record) {
      try {
        const operation = this.isRecallOperation
          ? findAgendaItemOperation(agendaItemOperation.recall)
          : findAgendaItemOperation(agendaItemOperation.submit);

        const action = `${operation?.label} Agenda Item`;

        const id = record?.id ?? -1;

        this.isRecallOperation
          ? await this.recallAgendaItem(id)
          : await this.submitAgendaItem(id);

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Clear Store Data
     */
    clearSavedRecord() {
      try {
        const payload = {
          type: undefined,
          record: undefined
        };
        this.setMovedRecord(payload);

        const batchPayload = {
          type: undefined,
          records: []
        };
        this.setMovedRecords(batchPayload);
      } catch (e) {
        console.error(e);
      }
    }
  },

  watch: {
    selectedItems() {
      this.isPurgeOperation = true;
      this.isUnlockOperation = true;
      this.isSetNonReadOnlyOperation = true;

      for (const record of this.selectedItems) {
        if (!(record?.isDeleted ?? false)) {
          this.isPurgeOperation = false;
        }

        if (!(record?.isReadOnly ?? false)) {
          this.isSetNonReadOnlyOperation = false;
        }

        if (!(record?.isLocked ?? false)) {
          this.isUnlockOperation = false;
        }
      }
    }
  }
};
