// model
import { CommandUI } from "@/model/common/commands/ui/CommandUI";
import { commandNames } from "@/model/common/commands/commandModel";

// Mixins
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";

export const commandUIFactoryMixin = {
  mixins: [buttonSettingsMixin, deleteSettingsMixin],
  methods: {
    /**
     * Create UI Command
     * @param {String} label
     * @returns {CommandUI}
     */
    createUICommand(label = commandNames.ok) {
      return new CommandUI(label, this.buttonColorClass);
    },

    /**
     * Create Delete UI Command
     * @param label
     * @returns {CommandUI}
     */
    createDeleteUICommand(label = commandNames.delete) {
      return new CommandUI(label, this.deleteColorClass);
    }
  }
};
