// Axillary
import { isOddNumber, itemCount, deepCopyArray, isValidDate } from "@/utils";

// config
import { isAllowedAuthor } from "@/services/config/configService";

// services
import {
  getStoredDataTableConfiguration,
  storeDataTableConfiguration,
  storeDataTableFieldsConfiguration,
  getDataTableFieldsConfiguration,
  storeDataTableRecordPropsConfiguration,
  getDataTableRecordPropsConfiguration
} from "@/services/user/userService";
import { notImplementedMethod } from "@/services/error/errorMessages";
import {
  headers,
  mapHeadersSearchableRecordProps
} from "@/model/document/documentModel";

import { isDevelopment } from "@/services/config/configService";

// design
import {
  iconSearch,
  iconView,
  iconConfigure,
  iconPrinter,
  iconAdd,
  iconSave,
  iconEdit,
  iconTrash,
  iconRefresh,
  iconNavigateToDetails,
  iconNotification,
  iconFilterMenu,
  iconFilter,
  iconLock,
  iconFile,
  iconReadOnly,
  iconCheck,
  iconFiles,
  iconDocument,
  iconRecord,
  iconDotsHorizontalCircleOutline,
  iconDotsHorizontal,
  iconFileDetails,
  iconResetFilter
} from "@/design/icon/iconConst";
import { className } from "@/design/styles/styleConst";

// model
import { eventNames } from "@/model/common/events/eventConst";
import { commandNames } from "@/model/common/commands/commandModel";
import { headerName } from "@/model/common/dataTable/dataTableConst";
import { BatchDelete } from "@/model/common/batch/BatchDelete";
import { BatchPrint } from "@/model/common/batch/BatchPrint";

// Mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";
import { commandUIFactoryMixin } from "@/mixins/shared/base/factory/commandUIFactoryMixin";
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import { dialogToolbarSettingsMixin } from "@/mixins/shared/base/settings/dialogToolbarSettingsMixin";
import {
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import moment from "moment";
import {
  agendaItemOperation,
  findAgendaItemOperation
} from "@/model/agenda/item/agendaItemModel";
/**
 * Base Table Mixin serves as a base class for *Table*.vue components
 */
export const dataTableMixin = {
  data() {
    return {
      /**
       * headers
       * @type {{text: string, value: string, visible: boolean}[]}
       */
      headers: this.defaultHeaders(),
      /**
       * list Headers
       * @type {{text: string, value: string, visible: boolean}[]}
       */
      listHeaders: [],
      /**
       * Data Table item key which is used as a data table unique key
       * @type {string}
       */
      itemKey: undefined,
      /*
       * Get an Array of selected item objects
       * @type {{any}[]}
       */
      selectedItems: [],
      singleSelect: false,
      search: "",
      multiSort: false,
      showSelectCheckBoxes: false,
      showCheckboxes: true,

      // Dialogs
      visibleSettingsDialog: false,
      visibleEditDialog: false,
      visibleReportDialog: false,

      // edit (selected item)
      editedIndex: -1,
      editedItem: undefined,
      editedItemCategory: undefined,
      defaultItem: undefined,

      // icons
      iconSearch: iconSearch,
      iconView: iconView,
      iconConfigure: iconConfigure,
      iconPrinter: iconPrinter,
      iconAdd: iconAdd,
      iconSave: iconSave,
      iconEdit: iconEdit,
      iconFileDetails: iconFileDetails,
      iconTrash: iconTrash,
      iconRefresh: iconRefresh,
      iconNavigateToDetails: iconNavigateToDetails,
      iconNotification: iconNotification,
      iconFilter: iconFilter,
      iconFilterMenu: iconFilterMenu,
      iconLock: iconLock,
      iconReadOnly: iconReadOnly,
      iconCheck: iconCheck,
      iconFiles: iconFiles,
      iconFile: iconFile,
      iconDocument: iconDocument,
      iconRecord: iconRecord,
      iconDotsHorizontal: iconDotsHorizontal,
      iconDotsHorizontalCircleOutline: iconDotsHorizontalCircleOutline,
      iconResetFilter: iconResetFilter,
      // events
      eventDeleteSelectedItems: eventNames.deleteSelectedItems,
      eventPrintSelectedItems: eventNames.printSelectedItems,
      eventConfigureDataTable: eventNames.configureDataTable
    };
  },
  mixins: [
    appMixin,
    baseComponentMixin,
    snackbarableMixin,
    commandUIFactoryMixin,
    buttonSettingsMixin,
    deleteSettingsMixin,
    dialogToolbarSettingsMixin,
    menuItemSettingsMixin
  ],
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    BaseTableLayout: () => import("@/views/shared/layout/BaseTableLayout"),
    LayoutDataTableToolbar: () =>
      import("@/components/shared/core/layout/LayoutDataTableToolbar"),
    TableToolbar: () =>
      import("@/components/shared/core/toolbars/TableToolbar"),
    BaseDialogToolbar: () =>
      import("@/components/shared/base/BaseDialogToolbar"),
    SearchTextField: () =>
      import("@/components/shared/base/BaseSearchTextField"),
    ActionButton: () => import("@/components/shared/core/buttons/ActionButton"),
    ActionTooltipButton: () =>
      import("@/components/shared/core/buttons/ActionTooltipButton"),
    EditFieldDialog: () =>
      import("@/components/shared/core/dialogs/EditFieldDialog"),
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    LayoutPopoverMenu: () =>
      import("@/components/shared/core/layout/menu/LayoutPopoverMenu"),
    ReportDialog: () => import("@/components/shared/core/dialogs/ReportDialog"),
    BaseTooltipIcon: () => import("@/components/shared/base/BaseTooltipIcon")
  },
  computed: {
    /**
     * Get Reset Search Filter Color
     * @return {string|*}
     */
    filterColor() {
      return this.menuItemColorClass;
    },

    /**
     * Data Table List Items
     * @return {*[]}
     */
    items() {
      return [];
    },
    /**
     * Get Edit Item name
     * Abstract computed getter
     * @return {string}
     */
    tableEditItemName() {
      return "Item";
    },

    /**
     * Get Current Entity Name
     * Abstract computed getter
     * @returns {String|string}
     */
    tableEntityName() {
      return "Item";
    },
    /**
     * Get current selected Module Item id
     * Abstract computed getter
     * @returns {String|string}
     */
    selectedModuleItemId() {
      return "-1";
    },

    /**
     * Get current selected Module Item name
     * Abstract computed getter
     * @return {string}
     */
    selectedModuleItemName() {
      return undefined;
    },

    /**
     * Get Table Title
     * Abstract computed getter
     * @returns {String|String}
     */
    tableTitle() {
      return this.selectedModuleItemName || "";
    },
    /**
     * Get Table Icon
     * Abstract computed getter
     * @returns {String|String}
     */
    tableIcon() {
      return undefined;
    },

    /**
     * Get filtered Headers
     * @return {{text: string, value: string, visible: boolean}[]}
     */
    filteredHeaders() {
      return this.headers.filter(el => !!el.visible);
    },

    /**
     * map Headers Searchable Record Properties
     * @return {{headerValue: string, propName: string}[]}
     */
    mapHeadersSearchableRecordProps() {
      return mapHeadersSearchableRecordProps;
    },

    /**
     * GetEdit Dialog Tile
     * @return {string}
     */
    editDialogTitle() {
      const name = this.editDialogCommandOk?.text ?? "";

      return name === commandNames.details
        ? `${name} of ${this.tableEditItemName}`
        : `${name} ${this.tableEditItemName}`;
    },

    /**
     * Edit Dialog Command Ok
     * @return {CommandUI}
     */
    editDialogCommandOk() {
      return this.editedIndex === -1
        ? this.createUICommand(commandNames.add)
        : this.createUICommand(commandNames.details);
    },

    /**
     * Display loading data table information
     * @return {string}
     */
    loadingText() {
      return `Loading ${this.tableTitle} ...`;
    },
    /**
     * Number of displayed items Per Page
     * @return {number}
     */
    itemsPerPage: () => 10,

    /**
     * Get selected Item Count
     * @return {number|number}
     */
    selectedItemCount() {
      return this.selectedItems?.length ?? 0;
    },

    /**
     * is Available Add Item
     * Available: when Authoring mode is in place, is permitted, is valid action
     * @return {boolean}
     */
    isAvailableAddItem() {
      // Currently not available, since authoring is done at the record details panel
      return false;
    },

    /**
     * is Available Delete Item
     * Available: when Authoring mode is in place, is permitted, is valid action
     * @return {boolean}
     */
    isAvailableDelete() {
      return this.isAllowedAuthor;
    },

    /**
     * is Available Print Item
     * Available: when it is implemented & is valid action
     * @return {boolean}
     */
    isAvailablePrint: () => false,
    isAvailableConfigure: () => true,

    /**
     * can Delete
     * @return {boolean}
     */
    canDelete() {
      return (
        !this.isLoading && this.isAvailableDelete && this.selectedItemCount > 0
      );
    },
    /**
     * batch Delete
     * @returns {BatchDelete}
     */
    batchDelete() {
      return new BatchDelete(
        this.tableEntityName,
        this.selectedItemCount,
        this.mdDeleteColor,
        this.mdDeleteColor
      );
    },
    /**
     * batch Print
     * @returns {BatchPrint}
     */
    batchPrint() {
      return new BatchPrint(
        this.tableEntityName,
        this.selectedItemCount,
        this.mdDialogTitleColor,
        this.mdDialogTitleColor
      );
    },
    /**
     * command Print
     * @returns {CommandUI}
     */
    commandPrint() {
      return this.createUICommand(commandNames.print);
    },

    /**
     * command Print Icon name
     * @return {string}
     */
    commandPrintIconName() {
      return findRecordOperation(recordOperation.Print)?.icon;
    },

    /**
     * command Delete Icon name
     * @return {string}
     */
    commandDeleteIconName() {
      return findRecordOperation(recordOperation.Delete)?.icon;
    },

    /**
     * command Restore Icon name
     * @return {string}
     */
    commandRestoreIconName() {
      return findRecordOperation(recordOperation.Restore)?.icon;
    },

    /**
     * command Lock Icon name
     * @return {string}
     */
    commandLockIconName() {
      return this.isUnlockOperation
        ? findRecordOperation(recordOperation.Unlock)?.icon
        : findRecordOperation(recordOperation.Lock)?.icon;
    },

    /**
     * command Read Only Icon name
     * @return {string}
     */
    commandReadOnlyIconName() {
      return findRecordOperation(recordOperation.SetReadOnly)?.icon;
    },

    /**
     * command Submit Icon name
     * @return {string}
     */
    commandSubmitIconName() {
      return findAgendaItemOperation(agendaItemOperation.submit)?.icon;
    },

    /**
     * command Recall Icon name
     * @return {string}
     */
    commandRecallIconName() {
      return findAgendaItemOperation(agendaItemOperation.recall)?.icon;
    },

    /**
     * command Download Icon name
     * @return {string}
     */
    commandDownloadIconName() {
      return findRecordOperation(recordOperation.Download)?.icon;
    },

    /**
     * command Download Label
     * @return {string}
     */
    commandEmailLabel() {
      return findRecordOperation(recordOperation.Email)?.label;
    },

    /**
     * command Restore Label
     * @return {string}
     */
    commandRestoreLabel() {
      return findRecordOperation(recordOperation.Restore)?.label;
    },

    /**
     * command Lock Label
     * @return {string}
     */
    commandLockLabel() {
      return this.isUnlockOperation
        ? findRecordOperation(recordOperation.Unlock)?.label
        : findRecordOperation(recordOperation.Lock)?.label;
    },

    /**
     * command Read Only Label
     * @return {string}
     */
    commandReadOnlyLabel() {
      return this.isSetNonReadOnlyOperation
        ? "Not Read Only"
        : findRecordOperation(recordOperation.SetReadOnly)?.label;
    },

    /**
     * command Submit Label
     * @return {string}
     */
    commandSubmitLabel() {
      return findAgendaItemOperation(agendaItemOperation.submit)?.label;
    },

    /**
     * command Recall Label
     * @return {string}
     */
    commandRecallLabel() {
      return findAgendaItemOperation(agendaItemOperation.recall)?.label;
    },

    /**
     * command Download Icon name
     * @return {string}
     */
    commandEmailIconName() {
      return findRecordOperation(recordOperation.Email)?.icon;
    },

    /**
     * is Allowed Author (still under development), so it is available for development only
     * @return {boolean}
     */
    isAllowedAuthor() {
      return isAllowedAuthor();
    },

    /**
     * visible Search by default
     * @return {boolean}
     */
    visibleSearch() {
      return (this?.items?.length ?? 0) > 1;
    },

    /**
     * Get Command Details
     * @return {string}
     */
    commandDetail() {
      const details = "Details ...";

      return this.editedItem
        ? `${this.editedItem.recordType} ${details}`
        : details;
    },

    /**
     * Get Command Details
     * @return {string}
     */
    commandDetailReport() {
      return "View Report";
    },

    /**
     * command View selected records Detail Report Tooltip
     * @return {string}
     */
    commandDetailReportTooltip() {
      return `View detailed report of ${this.selectedItems?.length ??
        0} selected records`;
    },

    /**
     * Compute if Detail Button Visible
     * @return {boolean}
     */
    visibleReportDetailAction() {
      return this.selectedItems?.length > 0 ?? false;
    },

    /**
     * Compute if Batch Commands Visible
     * @return {boolean}
     */
    visibleBatchCommands() {
      return (this.selectedItems?.length ?? 0) > 0 && this.isAllowedAuthor;
    },

    /**
     * Reset Filter Tooltip
     * @return {string}
     */
    resetFilterTooltip() {
      return `Clear All ${this.searchCriteriaList.length} Filters and Refresh Search`;
    },

    /**
     * Get Empty Table Data Text
     * @return {string}
     */
    getEmptyTableDataText() {
      return (this.searchCriteriaList?.length ?? 0) ||
        (this.category?.id ?? -1) >= 0
        ? "No Results Found"
        : "Search Conditions Not Provided";
    }
  },
  methods: {
    /**
     * Abstract method: Set Items
     * Note: This method has to be implemented by client class
     */
    async setItems() {
      throw notImplementedMethod("setItems");
    },

    /**
     * load Data Table Configuration & setItems
     * @return {Promise<void>}
     */
    async load() {
      try {
        this.clearError();
        this.isLoading = true;
        this.loadDataTableConfiguration();
        await this.setItems();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Get default Headers
     * @returns {{text: string, value: string, visible: boolean}[]}
     */
    defaultHeaders() {
      return this.deepCopyArray(headers);
    },

    /**
     * load Data Table Configuration
     */
    loadDataTableConfiguration() {
      try {
        this.headers = this.defaultHeaders() || [];

        const headerValues = this.storedHeaderValues();
        console.log(`headerValues:`, headerValues);

        if (itemCount(headerValues) > 0) {
          this.setDataTableColumnsVisibility(headerValues);
        }

        this.listHeaders = this.headers.filter(el => !!el.visible);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Stored Header Values
     * @return {{string}[]}
     */
    storedHeaderValues() {
      const headerValues = [];
      const props = this.dataTableRecordPropsConfiguration();

      if (itemCount(props) > 0) {
        const visibleProps = props.filter(p => !!p.include);

        visibleProps.forEach(vp => {
          const hrp = this.mapHeadersSearchableRecordProps.find(
            hrp => hrp.propName === vp.name
          );
          if (hrp) {
            headerValues.push(hrp.headerValue);
          } else {
            console.warn(`Couldn't find Searchable Record property:`, vp.name);
          }
        });
      }

      if (headerValues.length > 0) {
        return headerValues;
      }

      // Handle backwards compatibility
      return getStoredDataTableConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName
      );
    },
    async simulateLongProcess(timeOut = 2000) {
      return new Promise(resolve => setTimeout(resolve, timeOut));
    },
    showItemDetails(item) {
      this.$emit(eventNames.openSelectedItem, item);
    },
    setEditedItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    closeEditDialog() {
      this.visibleEditDialog = false;

      this.$nextTick(() => {
        this.setEditedItem();
        this.editedIndex = -1;
      });

      this.showSnackbarInfo(`Closed ${this.editDialogTitle}`);
    },
    save() {
      const action = this.editedIndex > -1 ? "Updated" : "Created";

      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }

      this.closeEditDialog();
      this.showSnackbarSuccess(`${action} ${this.tableEntityName}`);
    },
    /**
     * Client classes to implement
     * @param {BatchDelete} batchDelete
     */
    async deleteSelectedItems(batchDelete) {
      this.selectedItems?.forEach(el => {
        const index = this.items.indexOf(el);
        if (index >= 0) {
          this.items.splice(index, 1);
        }
      });
      //
      // Update batch progress/status ...
      // e.g. batchDelete.UpdateProgress()
      //
      await this.simulateLongProcess();

      this.showSnackbarSuccess(
        batchDelete ? batchDelete.outcome : "Deleted Selected Items",
        true
      );
    },
    /**
     * Client classes to implement it
     * @type BatchPrint batchPrint
     */
    async printSelectedItems(batchPrint) {
      //
      // Update batch progress/status ...
      // e.g. batchPrint.UpdateProgress()
      //
      await this.simulateLongProcess();

      this.showSnackbarSuccess(
        batchPrint ? batchPrint.outcome : "Printed Selected Items",
        true
      );
    },
    /**
     * Client classes to implement it
     */
    configureDataTable() {
      this.showSnackbarWarning(`TODO: configure Data Table!`, true);
    },
    /**
     * on Selected Notification handler
     * @param {{ title: String }} notification
     */
    onSelectedNotification(notification) {
      this.showSnackbarWarning(`TODO: handle ${notification.title}`, true);
    },
    rowBackgroundClassName(item) {
      const index = this.items.indexOf(item);
      return isOddNumber(index) ? "" : className.rowBackgroundColor;
    },
    async refreshList() {
      await this.setItems();
    },
    /**
     * Save Settings
     * @param {Array <string>} visibleColumns
     * @return {Promise<void>}
     */
    async onSettingsDialogOK(visibleColumns) {
      try {
        this.setDataTableColumnsVisibility(visibleColumns);
        this.saveDataTableConfiguration(visibleColumns);

        this.listHeaders = this.headers.filter(el => !!el.visible);

        await this.refreshList();

        this.visibleSettingsDialog = false;
      } catch (e) {
        console.error(e);
      }
    },
    onSettingsDialogCancel() {
      this.visibleSettingsDialog = false;
    },
    setDataTableColumnsVisibility(visibleColumns) {
      if (!visibleColumns) {
        this.ensureDataTableColumnsVisibility();
        return;
      }
      if (itemCount(this.headers) === 0) {
        return;
      }
      this.headers.forEach(header => {
        header.visible =
          header.value === headerName.actions
            ? true
            : !!visibleColumns.find(el => el === header.value);
      });
    },
    ensureDataTableColumnsVisibility() {
      this.headers.forEach(header => {
        header.visible = !!header.visible;
      });
    },
    /**
     * Save DataTable Configuration to local store
     * @param {Array <string>} visibleColumns
     */
    saveDataTableConfiguration(visibleColumns) {
      storeDataTableConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName,
        visibleColumns
      );
    },
    /**
     * store Searchable Fields
     * @param {Array <{Id:number, Include:boolean, Value:any, Operator:string}>} fields
     */
    storeSearchableFields(fields) {
      storeDataTableFieldsConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName,
        fields
      );
    },
    /**
     * Get dataTable Fields Configuration
     * @return {Array <{Id:number, Include:boolean, Value:any, Operator:string}>}
     */
    dataTableFieldsConfiguration() {
      return getDataTableFieldsConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName
      );
    },

    /**
     * Get data Table Record Properties Configuration
     * @return {{name: string, include: boolean, operator: string, value: string}[]}
     */
    dataTableRecordPropsConfiguration() {
      return getDataTableRecordPropsConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName
      );
    },
    storeSearchableRecordProps(props) {
      storeDataTableRecordPropsConfiguration(
        this.appModuleName,
        this.selectedModuleItemId,
        this.componentName,
        props
      );
    },
    visibleHeader(value) {
      const header = this.findHeader(value);

      return header?.visible ?? false;
    },
    findHeader(value) {
      return this?.headers?.find(el => el.value === value);
    },
    deepCopyArray(array) {
      return deepCopyArray(array);
    },

    /**
     * determine whether app is running in development environment
     * @return {boolean} true if app is running in development environment
     */
    isDevelopment() {
      return isDevelopment;
    },

    /**
     * Open Report Dialog
     */
    openReportDialog() {
      this.visibleReportDialog = true;
    },

    /**
     * command View Record Details Label
     * @return {string}
     */

    /**
     * View Record Details Label
     * @param record
     * @return {string}
     */
    commandViewRecordDetailsLabel(record) {
      const action = findRecordOperation(recordOperation.ViewDetails)?.label;
      return record ? `${record.recordType} ${action} ...` : `${action} ...`;
    },

    /**
     * command Print Label
     * @param record
     * @return {string}
     */
    commandPrintLabel(record) {
      const action = findRecordOperation(recordOperation.Print)?.label;
      return record ? `${action} ${record.recordType}` : `${action}`;
    },

    /**
     * command Download Label
     * @param record
     * @return {string}
     */
    commandDownloadLabel(record) {
      const action = findRecordOperation(recordOperation.Download)?.label;
      return record ? `${action} ${record.recordType}` : `${action}`;
    },

    /**
     * on Search Condition (Chip) Removed
     * @param item - Search Filter Chip
     * @param performSearch - Perform Search after chip cleared
     * @return {Promise<void>}
     */
    async onSearchChipRemoved(item, performSearch = true) {
      try {
        this.isLoading = true;

        //Filter Flying Fields
        this.filterSearchableFlyingFields.forEach(field => {
          if (field.name === item.name) {
            field.value = "";
          }
        });

        //Filter Category Fields
        this.filterSearchableRecordCategoryFields.forEach(field => {
          if (field.name === item.name) {
            field.value = "";
          }
        });

        //Filter Full Text Search
        if (item.name === this.fieldFullText.name) {
          this.fieldFullText.value = "";
        }

        //Filter GL=lobal Search
        if (item.name === this.fieldUniversalSearch.name) {
          this.fieldUniversalSearch.value = "";
        }

        //Filter Record Props
        this.filterSearchableRecordProps.forEach(prop => {
          if (prop.name === item.name) {
            prop.field.value = "";
          }
        });

        // Apply Search After Chip Removed
        if (performSearch) await this.applySearchFilter();
      } catch (e) {
        console.error(e.toString());
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Perform Reset Filter
     */
    performFilterReset() {
      this.resetSearchableRecordProperties();

      // ensure table headers are not reset (id,type,created etc)
      // Remove if hard reset required
      const props = this.defaultSearchableRecordProps(this.defaultHeaders());
      this.loadSearchRecordProps(props);

      // This will reset all the fields
      this.categorySearchableFields = [];
      this.selectedFlyingFields = [];
      this.fieldFullText.value = "";

      //Reset Search Text Field
      this.search = "";
    },

    /**
     * On Apply Filter Dialog
     */
    async onFilterDialogApply() {
      await this.applySearchFilter(false);
    },

    /**
     * Restore Filter Content from local store
     */
    restoreFilterContent() {
      try {
        this.performFilterReset();
        let fields = this.dataTableFieldsConfiguration() || [];

        this.loadSearchRecordFields(fields);

        let props = this.dataTableRecordPropsConfiguration();
        const propsCount = this.countSearchableFilterProps(props);

        // console.log(`setItems() props:`, props);
        // console.log(`setItems() propsCount:`, propsCount);

        if (propsCount === 0) {
          props = this.defaultSearchableRecordProps(this.defaultHeaders());
        }
        this.loadSearchRecordProps(props);
      } catch (e) {
        console.error(e.toString());
      }
    },

    /**
     * Custom Sort Table Items
     * Currently used to correct sort by date behaviour
     * @param {Object} items - List Items
     * @param {Array} sortBy - Selected Headers
     * @param {Boolean} sortDesc
     * @return {*}
     */
    customSort(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          const sortByField = sortBy[i];
          const sortDirection = sortDesc[i] ? -1 : 1;

          // Check if sortByField is a date type/ is valid Date
          if (isValidDate(a[sortByField])) {
            const dateA = moment(a[sortByField], "MMM D, YYYY");
            const dateB = moment(b[sortByField], "MMM D, YYYY");
            if (dateA.isBefore(dateB)) return -1 * sortDirection;
            if (dateA.isAfter(dateB)) return 1 * sortDirection;
          } else {
            const valueA = (a[sortByField] || "").toString().toLowerCase();
            const valueB = (b[sortByField] || "").toString().toLowerCase();
            if (valueA < valueB) return -1 * sortDirection;
            if (valueA > valueB) return 1 * sortDirection;
          }
        }
        return 0; // Return 0 if all sorting criteria are equal
      });
    }
  },
  async created() {
    await this.load();
  }
};
