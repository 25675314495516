import { commandNames } from "@/model/common/commands/commandModel";
import { Icon } from "@/design/icon/Icon";
import { Color } from "@/design/colors/Color";

/**
 * Batch Command class
 */
class BaseBatch {
  /**
   * Command Name
   * @type {string}
   */
  name = commandNames.ok;
  /**
   * entity Name
   * @type {string}
   */
  itemName = "Item";
  /**
   * Item Count
   * @type {number}
   */
  itemCount = 0;
  /**
   * Command Icon
   * @type {Icon}
   */
  icon = undefined;
  /**
   * Get selection Information
   * @returns {string}
   */
  get selectionInfo() {
    return `${this.itemCount} selected ${this.itemName}(s)`;
  }
  /**
   * Get Batch Command title
   * @returns {string}
   */
  get title() {
    return `${this.name} ${this.itemName}`;
  }
  /**
   * Title Color
   * @type {Color}
   */
  titleColor = undefined;
  /**
   * Get title Color Class
   * @returns {string|*|undefined}
   */
  get titleColorClass() {
    return this.titleColor ? this.titleColor.getClassColor() : undefined;
  }
  /**
   * Get Batch Command prompt
   * @returns {string}
   */
  get prompt() {
    return `${this.name} ${this.itemCount} selected ${this.itemName}(s)?`;
  }
  /**
   * Get Batch Command progress
   * @returns {string}
   */
  get progress() {
    return `${this.name}ing ${this.selectionInfo} ...`;
  }
  /**
   * Get Batch Command outcome information
   * @returns {string}
   */
  get outcome() {
    return `${this.name}ed ${this.selectionInfo}.`;
  }
  /**
   * construct Batch Command
   * @param {string} name
   * @param {string} itemName
   * @param {Number} itemCount
   * @param {String} iconName
   * @param {Color} iconColor
   * @param {Color} titleColor
   */
  constructor(
    name = "",
    itemName = "Item",
    itemCount = 0,
    iconName = undefined,
    iconColor = new Color(),
    titleColor = new Color()
  ) {
    this.name = name;
    this.itemName = itemName;
    this.itemCount = itemCount;
    this.icon = new Icon(iconName, iconColor);
    this.titleColor = titleColor ? titleColor : new Color();
  }
}

export { BaseBatch };
