import { commandNames } from "@/model/common/commands/commandModel";
import { BaseBatch } from "@/model/common/batch/BaseBatch";
import { iconPrinter } from "@/design/icon/iconConst";
import { Color, colorTheme } from "@/design/colors/Color";

class BatchPrint extends BaseBatch {
  /**
   * construct Batch Print Command
   * @param {String} itemName
   * @param {Number} itemCount
   * @param {Color} iconColor
   * @param {Color} titleColor
   */
  constructor(
    itemName = "Item",
    itemCount = 0,
    iconColor = new Color(colorTheme.primary),
    titleColor = new Color(colorTheme.primary)
  ) {
    super(
      commandNames.print,
      itemName,
      itemCount,
      iconPrinter,
      iconColor,
      titleColor
    );
  }
}

export { BatchPrint };
