/**
 * Icon Class
 */
import { Color } from "@/design/colors/Color";

// import { Color } from "@/design/colors/Color";

class Icon {
  /**
   * Icon name
   * @type {string}
   */
  name = "";

  /**
   * Icon Color
   * @type { Color}
   */
  color = undefined;

  /**
   * Applies the dark theme variant to the component
   * @type {boolean}
   */
  dark = false;

  /**
   * Disable the input
   * @type {boolean}
   */
  disabled = false;

  /**
   * Applies appropriate margins to the icon inside of a button when placed to the left of another element or text
   * @type {boolean}
   */
  left = false;

  /**
   * Applies appropriate margins to the icon inside of a button when placed to the right of another element or text
   * @type {boolean}
   */
  right = false;

  /**
   * Applies the light theme variant to the icon.
   * @type {boolean}
   */
  light = false;

  /**
   * Makes the icon large
   * @type {boolean}
   */
  large = false;

  /**
   * Construct UI Icon
   * @param {String} name
   * @param {Color} color
   */
  constructor(name = "", color = undefined) {
    this.name = name;
    this.color = color ? color : new Color();
  }

  /**
   * Get icon Material Design color class
   * @returns {string|*|undefined}
   */
  get colorClass() {
    return this.color ? this.color.getClassColor() : undefined;
  }
}

export { Icon };
