// design
import {
  iconCategories,
  iconFilter,
  iconFilterMenu
} from "@/design/icon/iconConst";

// services
import { notImplementedMethod } from "@/services/error/errorMessages";

// mixins
import { dataTableDocumentsMixin } from "@/mixins/shared/documents/dataTableDocumentsMixin";
import { searchableMixin } from "@/mixins/shared/searchable/searchableMixin";

/**
 * dataTableSearchableDocumentsMixin
 */
export const dataTableSearchableDocumentsMixin = {
  mixins: [dataTableDocumentsMixin, searchableMixin],
  components: {
    SearchFormField: () => import("@/components/field/SearchFormField"),
    SearchFormFieldRange: () =>
      import("@/components/field/SearchFormFieldRange"),
    SearchSelectFormField: () =>
      import("@/components/field/SearchSelectFormField"),
    LayoutDialog: () =>
      import("@/components/shared/core/layout/dialog/LayoutDialog"),
    BaseToolbar: () => import("@/components/shared/base/BaseToolbar"),
    MoreFieldsPopoverMenu: () =>
      import("@/components/field/MoreFieldsPopoverMenu"),
    MoreFields: () => import("@/components/field/MoreFields"),
    SearchFilterPopoverMenu: () =>
      import("@/components/search/SearchFilterPopoverMenu"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  data() {
    return {
      iconFilter: iconFilter,
      iconFilterMenu: iconFilterMenu,
      iconCategories: iconCategories
    };
  },
  computed: {
    /**
     * Get search Category
     * @return {*|{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]|(function(): {id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[])}
     */
    searchCategory() {
      return undefined;
    },

    /**
     * Get selected Category
     * @return {*|{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]|(function(): {id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags: number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[])}
     */
    selectedCategory() {
      return this.searchCategory ? this.searchCategory : this.recordCategory;
    },

    /**
     * Get Search Filter Dialog Title
     * @return {string|string}
     */
    filterDialogTitle() {
      return (this.record?.id ?? 0) > 0
        ? `Search content of ${this.record?.name ?? "Record"}`
        : "Search";
    },

    /**
     * Data Table Title
     * Overwrites dataTableDocumentsMixin.dataTableTitle
     * @return {string} Formatted Data Table Title
     */
    dataTableTitle() {
      return this.hasFilter ? "Search:" : "";
    },

    /**
     * full Text Search Criteria
     * @return {String|undefined} full Text Search Criteria
     */
    fullTextSearchCriteria() {
      return this.fieldFullText?.value ?? undefined;
    },

    /**
     * Command Browse Search Template
     * @return {string}
     */
    commandBrowse: () => "Browse",

    /**
     * Command Save Search Template
     * @return {string}
     */
    commandSave: () => "Save"
  },
  methods: {
    onFilterDialogCancel() {
      try {
        this.restoreFilterContent();
        this.menuFilterVisible = false;
      } catch (e) {
        console.error(e);
      }
    },
    async refreshList() {
      await this.applySearchFilter();
    },
    async onFilterDialogOk() {
      await this.applySearchFilter();
    },
    onFilterDialogReset() {
      this.resetSearchFilter();
    },
    resetSearchFilter(applySearch = false) {
      throw notImplementedMethod(`resetSearchFilter() ${applySearch}`);
    },
    async applySearchFilter() {
      throw notImplementedMethod(`applySearchFilter()`);
    },
    ensureValidFolderSearchCriteria(payload) {
      throw notImplementedMethod(
        `ensureValidFolderSearchCriteria() ${payload}`
      );
    },
    async onFilterDialogBrowse() {
      await this.getUserSearchTemplate();
    },
    async getUserSearchTemplate() {
      throw notImplementedMethod(`getUserSearchTemplate()`);
    },
    async onFilterDialogSave() {
      await this.saveUserSearchTemplate();
    },
    async saveUserSearchTemplate() {
      throw notImplementedMethod(`saveUserSearchTemplate()`);
    }
  }
};
