// design
import { Color } from "@/design/colors/Color";
import { iconTrash } from "@/design/icon/iconConst";
import { colorTheme } from "@/design/colors/mdColors";

// model
import { commandNames } from "@/model/common/commands/commandModel";
import { BaseBatch } from "@/model/common/batch/BaseBatch";

class BatchDelete extends BaseBatch {
  /**
   * Get Batch Delete Command prompt
   * @returns {string}
   */
  get prompt() {
    return `Are you sure you want to ${this.name} ${this.selectionInfo}?`;
  }
  /**
   * construct Batch Delete Command
   * @param {String|string} itemName
   * @param {Number|number} itemCount
   * @param {Color} iconColor
   * @param {Color} titleColor
   */
  constructor(
    itemName = "Item",
    itemCount = 0,
    iconColor = new Color(colorTheme.error),
    titleColor = new Color(colorTheme.error)
  ) {
    super(
      commandNames.delete,
      itemName,
      itemCount,
      iconTrash,
      iconColor,
      titleColor
    );
  }
}

export { BatchDelete };
