// mixins
import { recordAttributeMixin } from "@/mixins/shared/record/recordAttributeMixin";
import { recordStateColorMixin } from "@/mixins/shared/record/recordStateColorMixin";

export const chippableRecordMixin = {
  mixins: [recordAttributeMixin, recordStateColorMixin],
  methods: {
    chipRecordStateColor(record) {
      return this.recordStateColor(record);
    },
    chipRecordStateTextColor(record) {
      return this.recordStateTextColor(record);
    },
    chipRecordHasAttributeIcon(record) {
      return this.hasAttributeIcon(record);
    },
    chipRecordAttributeIcon(record) {
      return this.recordAttributeIcon(record);
    },
    chipRecordAttributeIconColor(record) {
      return this.recordAttributeColor(record);
    }
  }
};
