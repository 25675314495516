/**
 * Class Command UI
 */
class CommandUI {
  /**
   * Command Text
   * @type {string}
   */
  text = "";
  /**
   * Command Text Color
   * @type {string}
   */
  textColor = undefined;
  /**
   * Set/Get Command UI visibility
   * @type {Boolean}
   */
  visible = true;
  /**
   * Set/Get Command UI disability
   * @type {Boolean}
   */
  disabled = false;
  /**
   * Construct Command UI
   * @param {string} text
   * @param {string} textColor
   */
  constructor(text = "", textColor = undefined) {
    this.text = text;
    this.textColor = textColor;
  }
}

export { CommandUI };
